<template>
  <teleport to="body">
    <TransitionRoot as="template" :show="open">
      <div class="relative z-30">
        <TransitionChild
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500/75 transition-opacity" />
        </TransitionChild>

        <div
          class="fixed inset-0"
          :class="[sizeType === 'fullScreen' ? '' : 'overflow-y-auto']"
          @click.stop="closeBackdrop">
          <div class="flex min-h-full items-start justify-center text-center sm:items-center">
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div
                :class="dialogPanelClass"
                class="relative flex transform flex-col p-4 text-left transition-all">
                <div
                  class="flex max-h-full flex-grow flex-col rounded-lg shadow-xl"
                  :class="[noPadding ? 'p-0' : 'p-9', bg ? bg : 'bg-white']"
                  @click.stop>
                  <div
                    class="flex items-center justify-between"
                    :class="[noPadding ? 'p-4' : 'pb-9']"
                    v-if="!hideTopBar">
                    <div>
                      <slot name="title"></slot>
                      <template v-if="title">
                        <div class="text-xl font-medium">{{ title }}</div>
                      </template>
                    </div>
                    <div @click="handleClose" class="cursor-pointer">
                      <x-mark-icon class="h-6 w-6" />
                    </div>
                  </div>

                  <slot v-bind="{ close: handleClose }" />
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </div>
    </TransitionRoot>
  </teleport>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { XMarkIcon } from "@heroicons/vue/20/solid"
import { TransitionChild, TransitionRoot } from "@headlessui/vue"
import type { ModalProps } from "/js/components/Modals/ModalTypes"

const open = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit("update:modelValue", value)
    emit("close")
  },
})

const emit = defineEmits<{
  "update:modelValue": [open: boolean]
  close: []
}>()

const props = withDefaults(defineProps<ModalProps>(), {
  modelValue: false,
  hideTopBar: false,
  noBackdropClose: false,
  sizeType: "default",
  confirmModalClose: false,
})

const dialogPanelClass = computed(() => {
  switch (props.sizeType) {
    case "fullScreen":
      return "h-screen w-screen max-w-10xl"
    case "default":
      return "w-full sm:my-8 sm:max-w-6xl"
    case "auto":
      return "max-w-full"
    default:
      return "w-full"
  }
})

const closeBackdrop = () => {
  if (!props.noBackdropClose) {
    handleClose()
  }
}

const handleClose = () => {
  if (props.confirmModalClose) {
    if (confirm("You have unsaved changes. Are you sure you want to leave?")) {
      open.value = false
    }
  } else {
    open.value = false
  }
}
</script>

<script lang="ts">
export default {
  name: "AppModal",
}
</script>
