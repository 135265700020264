import { z } from "zod"

export type Identifiable<T = string> = {
  id: T
}

export const ZIdentifiable = z.object({
  id: z.string(),
})

export type LocalIdentifiable = {
  identifier: string | undefined | null
}