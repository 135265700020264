import { z } from "zod"
import type { LocalIdentifiable } from "/js/models/Identifiable"
import { nanoid } from "nanoid"
import type { ResumeCategory } from "/js/models/Resume"

export const personalInfoResumeItemType = z.literal("PersonalInfoResumeItem")
export type PersonalInfoResumeItemType = z.infer<typeof personalInfoResumeItemType>
export const summaryResumeItemType = z.literal("SummaryResumeItem")
export type SummaryResumeItemType = z.infer<typeof summaryResumeItemType>
export const educationResumeItemType = z.literal("EducationResumeItem")
export type EducationResumeItemType = z.infer<typeof educationResumeItemType>
export const experienceResumeItemType = z.literal("ExperienceResumeItem")
export type ExperienceResumeItemType = z.infer<typeof experienceResumeItemType>
export const skillResumeItemType = z.literal("SkillResumeItem")
export type SkillResumeItemType = z.infer<typeof skillResumeItemType>
export const certificationResumeItemType = z.literal("CertificationResumeItem")
export type CertificationResumeItemType = z.infer<typeof certificationResumeItemType>
export const languageResumeItemType = z.literal("LanguageResumeItem")
export type LanguageResumeItemType = z.infer<typeof languageResumeItemType>
export const projectResumeItemType = z.literal("ProjectResumeItem")
export type ProjectResumeItemType = z.infer<typeof projectResumeItemType>
export const organizationResumeItemType = z.literal("OrganizationResumeItem")
export type OrganizationResumeItemType = z.infer<typeof organizationResumeItemType>
export const courseResumeItemType = z.literal("CourseResumeItem")
export type CourseResumeItemType = z.infer<typeof courseResumeItemType>
export const coverLetterResumeItemType = z.literal("CoverLetterResumeItem")
export type CoverLetterResumeItemType = z.infer<typeof coverLetterResumeItemType>

const ZResumeItemTypes = z.enum([
  summaryResumeItemType.value,
  educationResumeItemType.value,
  experienceResumeItemType.value,
  skillResumeItemType.value,
  certificationResumeItemType.value,
  languageResumeItemType.value,
  personalInfoResumeItemType.value,
  projectResumeItemType.value,
  organizationResumeItemType.value,
  courseResumeItemType.value,
  coverLetterResumeItemType.value,
])

export type ResumeDisplayItem =
  | {
  id: string
  type: "section_header"
  title: string
}
  | {
  id: string
  type: "entry"
  content: ResumeItemParams
}

export type ResumeItemType = z.infer<typeof ZResumeItemTypes>
export const ZResumeItemBase = z.object({
  id: z.string().nullable().optional(),
  position: z.number().nullable().optional(),
})

export const ZPersonalInfoResumeItemData = z.object({
  first_name: z.string().nullable(),
  last_name: z.string().nullable(),
  avatar_url: z.string().nullable(),
  email: z.string().nullable(),
  phone: z.string().nullable(),
  address: z.string().nullable(),
  linkedin_url: z.string().nullable(),
  job_title: z.string().nullable(),
})
export type PersonalInfoResumeItemParams = LocalIdentifiable & {
  id?: string | null
  position?: number | null
  first_name?: string | null
  last_name?: string | null
  avatar?: string | null
  email?: string | null
  phone?: string | null
  address?: string | null
  linkedin_url?: string | null
  avatar_url?: string | null
  job_title?: string | null
  type: PersonalInfoResumeItemType
}
export const ZPersonalInfoResumeItem = z.object({
  type: personalInfoResumeItemType,
  ...ZPersonalInfoResumeItemData.shape,
  ...ZResumeItemBase.shape,
})

export const ZSummaryResumeItemData = z.object({
  text: z.string().nullable(),
})
export const ZSummaryResumeItem = z.object({
  type: summaryResumeItemType,
  ...ZSummaryResumeItemData.shape,
  ...ZResumeItemBase.shape,
})
export type SummaryResumeItemParams = LocalIdentifiable & {
  type: SummaryResumeItemType
  id?: string | null
  position?: number | null
  text: string
}

export const ZEducationResumeItemData = z.object({
  school: z.string().nullable(),
  degree: z.string().nullable(),
  field_of_study: z.string().nullable(),
  start_date: z.date().nullable(),
  end_date: z.date().nullable(),
  ongoing: z.boolean().nullable(),
  description: z.string().nullable(),
})
export const ZEducationResumeItem = z.object({
  type: educationResumeItemType,
  ...ZEducationResumeItemData.shape,
  ...ZResumeItemBase.shape,
})
export type EducationResumeItemParams = LocalIdentifiable & {
  id?: string | null
  position?: number | null
  school: string | null
  degree?: string | null
  field_of_study?: string | null
  start_date?: Date | null
  end_date?: Date | null
  ongoing?: boolean
  description?: string | null
  type: EducationResumeItemType
}

export const ZExperienceResumeItemData = z.object({
  company: z.string().nullable(),
  title: z.string().nullable(),
  start_date: z.date().nullable(),
  ongoing: z.boolean().nullable(),
  location: z.string().nullable(),
  end_date: z.date().nullable(),
  description: z.string().nullable(),
})
export const ZExperienceResumeItem = z.object({
  type: experienceResumeItemType,
  ...ZExperienceResumeItemData.shape,
  ...ZResumeItemBase.shape,
})
export type ExperienceResumeItemParams = LocalIdentifiable & {
  id?: string | null
  position?: number | null
  company?: string | null
  title?: string | null
  location?: string | null
  start_date?: Date | null
  end_date?: Date | null
  ongoing?: boolean
  description?: string | null
  type: ExperienceResumeItemType
}

export const SkillProficiencies = ["beginner", "intermediate", "advanced", "expert"] as const
export type SkillProficiency = (typeof SkillProficiencies)[number]
export const SkillProficiencyLabels: Record<SkillProficiency, string> = {
  beginner: "Beginner",
  intermediate: "Intermediate",
  advanced: "Advanced",
  expert: "Expert",
}

export const ZSkillResumeItemData = z.object({
  skill_name: z.string().nullable(),
  skill_proficiency: z.enum(SkillProficiencies).nullable(),
})
export type SkillResumeItemParams = LocalIdentifiable & {
  id?: string | null
  position?: number | null
  skill_name: string | null
  skill_proficiency?: SkillProficiency | null
  type: SkillResumeItemType
}
export const ZSkillResumeItem = z.object({
  type: skillResumeItemType,
  ...ZSkillResumeItemData.shape,
  ...ZResumeItemBase.shape,
})

export const ZCertificationResumeItemData = z.object({
  certification_name: z.string().nullable(),
  issuing_org: z.string().nullable(),
  issue_date: z.date().nullable(),
  expiration_date: z.date().nullable(),
  credential_id: z.string().nullable(),
  credential_url: z.string().nullable(),
})
export const ZCertificationResumeItem = z.object({
  type: certificationResumeItemType,
  ...ZCertificationResumeItemData.shape,
  ...ZResumeItemBase.shape,
})
export type CertificationItemParams = LocalIdentifiable & {
  id?: string | null
  position?: number | null
  certification_name: string | null
  issuing_org: string | null
  issue_date?: Date | null
  expiration_date?: Date | null
  credential_id?: string | null
  credential_url?: string | null
  type: CertificationResumeItemType
}

export const ZLanguageProficiencies = [
  "elementary",
  "limited",
  "professional",
  "full_professional",
  "native",
] as const
export type LanguageProficiency = (typeof ZLanguageProficiencies)[number]
export const LanguageProficiencyLabels: Record<LanguageProficiency, string> = {
  elementary: "Elementary",
  limited: "Limited",
  professional: "Professional",
  full_professional: "Full Professional",
  native: "Native",
}
export const ZLanguageResumeItemData = z.object({
  language_name: z.string(),
  language_proficiency: z.enum(ZLanguageProficiencies).nullable().optional(),
})
export const ZLanguageResumeItem = z.object({
  type: languageResumeItemType,
  ...ZLanguageResumeItemData.shape,
  ...ZResumeItemBase.shape,
})
export type LanguageResumeItemParams = LocalIdentifiable & {
  id?: string | null
  position?: number | null
  language_name: string
  language_proficiency?: LanguageProficiency | null
  type: LanguageResumeItemType
}

export const ZOrganizationResumeItemData = z.object({
  organization: z.string().nullable(),
  title: z.string().nullable(),
  start_date: z.date().nullable(),
  end_date: z.date().nullable(),
  ongoing: z.boolean().nullable(),
  location: z.string().nullable(),
  description: z.string().nullable(),
})
export const ZOrganizationResumeItem = z.object({
  type: organizationResumeItemType,
  ...ZOrganizationResumeItemData.shape,
  ...ZResumeItemBase.shape,
})
export type OrganizationResumeItemParams = LocalIdentifiable & {
  id?: string | null
  organization?: string | null
  title?: string | null
  location?: string | null
  start_date?: Date | null
  end_date?: Date | null
  ongoing?: boolean
  description?: string | null
  type: OrganizationResumeItemType
}

export const ZCourseResumeItemData = z.object({
  course_name: z.string().nullable(),
  course_website: z.string().nullable(),
  institution: z.string().nullable(),
  start_date: z.date().nullable(),
  end_date: z.date().nullable(),
  ongoing: z.boolean().nullable(),
  description: z.string().nullable(),
})
export const ZCourseResumeItem = z.object({
  type: courseResumeItemType,
  ...ZCourseResumeItemData.shape,
  ...ZResumeItemBase.shape,
})
export type CourseResumeItemParams = LocalIdentifiable & {
  id?: string | null
  course_name: string | null
  course_website: string | null
  institution: string | null
  start_date?: Date | null
  end_date?: Date | null
  ongoing?: boolean
  description?: string | null
  type: CourseResumeItemType
}

export const ZProjectResumeItemData = z.object({
  project_name: z.string().nullable(),
  project_website: z.string().nullable(),
  role: z.string().nullable(),
  start_date: z.date().nullable(),
  end_date: z.date().nullable(),
  ongoing: z.boolean().nullable(),
  description: z.string().nullable(),
})
export const ZProjectResumeItem = z.object({
  type: projectResumeItemType,
  ...ZProjectResumeItemData.shape,
  ...ZResumeItemBase.shape,
})
export type ProjectResumeItemParams = LocalIdentifiable & {
  id?: string | null
  project_name: string | null
  project_website: string | null
  role: string | null
  start_date?: Date | null
  end_date?: Date | null
  ongoing?: boolean
  description?: string | null
  type: ProjectResumeItemType
}

export const ZCoverLetterResumeItemData = z.object({
  company: z.string().nullable(),
  company_street: z.string().nullable(),
  company_city: z.string().nullable(),
  first_paragraph: z.string().nullable(),
  second_paragraph: z.string().nullable(),
  third_paragraph: z.string().nullable(),
  closing: z.string().nullable(),
  is_freeform: z.string().nullable(),
  freeform_text: z.string().nullable(),
})
export const ZCoverLetterResumeItem = z.object({
  type: coverLetterResumeItemType,
  ...ZCoverLetterResumeItemData.shape,
  ...ZResumeItemBase.shape,
})
export type CoverLetterResumeItemParams = LocalIdentifiable & {
  id?: string | null
  type: CoverLetterResumeItemType
  company: string | null
  company_street: string | null
  company_city: string | null
  first_paragraph: string | null
  second_paragraph: string | null
  third_paragraph: string | null
  closing: string | null
  is_freeform: string | null
  freeform_text: string | null
}

export const ZResumeItem = z.union([
  ZPersonalInfoResumeItem,
  ZEducationResumeItem,
  ZExperienceResumeItem,
  ZSkillResumeItem,
  ZCertificationResumeItem,
  ZLanguageResumeItem,
  ZSummaryResumeItem,
  ZOrganizationResumeItem,
  ZCourseResumeItem,
  ZProjectResumeItem,
  ZCoverLetterResumeItem,
])

export type ResumeItem = z.infer<typeof ZResumeItem>
export type PersonalInfoResumeItem = z.infer<typeof ZPersonalInfoResumeItem>
export type EducationResumeItem = z.infer<typeof ZEducationResumeItem>
export type ExperienceResumeItem = z.infer<typeof ZExperienceResumeItem>
export type SkillResumeItem = z.infer<typeof ZSkillResumeItem>
export type CertificationResumeItem = z.infer<typeof ZCertificationResumeItem>
export type LanguageResumeItem = z.infer<typeof ZLanguageResumeItem>
export type SummaryResumeItem = z.infer<typeof ZSummaryResumeItem>
export type OrganizationResumeItem = z.infer<typeof ZOrganizationResumeItem>
export type CourseResumeItem = z.infer<typeof ZCourseResumeItem>
export type CoverLetterResumeItem = z.infer<typeof ZCoverLetterResumeItem>
export type ProjectResumeItem = z.infer<typeof ZProjectResumeItem>

export type ResumeItemParams =
  | PersonalInfoResumeItemParams
  | EducationResumeItemParams
  | ExperienceResumeItemParams
  | SkillResumeItemParams
  | CertificationItemParams
  | LanguageResumeItemParams
  | SummaryResumeItemParams
  | OrganizationResumeItemParams
  | CourseResumeItemParams
  | CoverLetterResumeItemParams
  | ProjectResumeItemParams

export const resumeCategoryLabel = (category: ResumeCategory): string => {
  switch (category) {
    case "personal_info":
      return "Personal Info"
    case "summary":
      return "Summary"
    case "education":
      return "Education"
    case "experience":
      return "Experience"
    case "skills":
      return "Skills"
    case "certifications":
      return "Certifications"
    case "languages":
      return "Languages"
    case "organizations":
      return "Organizations"
    case "courses":
      return "Courses"
    case "projects":
      return "Projects"
    case "cover_letter":
      return "Cover Letter"
  }
}

export const resumeItemToItemParams = (item: ResumeItem): ResumeItemParams => {
  switch (item.type) {
    case "PersonalInfoResumeItem":
      return {
        identifier: nanoid(),
        id: item.id,
        position: item.position,
        first_name: item.first_name,
        last_name: item.last_name,
        avatar_url: item.avatar_url,
        email: item.email,
        phone: item.phone,
        address: item.address,
        linkedin_url: item.linkedin_url,
        job_title: item.job_title,
        type: personalInfoResumeItemType.value,
      }
    case "SummaryResumeItem":
      return {
        identifier: nanoid(),
        id: item.id,
        position: item.position,
        text: item.text ?? "",
        type: summaryResumeItemType.value,
      }
    case "EducationResumeItem":
      return {
        identifier: nanoid(),
        id: item.id,
        position: item.position,
        school: item.school,
        degree: item.degree,
        field_of_study: item.field_of_study,
        start_date: item.start_date,
        end_date: item.end_date,
        ongoing: item.ongoing ?? false,
        description: item.description,
        type: educationResumeItemType.value,
      }
    case "ExperienceResumeItem":
      return {
        identifier: nanoid(),
        id: item.id,
        position: item.position,
        company: item.company,
        title: item.title,
        location: item.location,
        start_date: item.start_date,
        end_date: item.end_date,
        ongoing: item.ongoing ?? false,
        description: item.description,
        type: experienceResumeItemType.value,
      }
    case "SkillResumeItem":
      return {
        identifier: nanoid(),
        id: item.id,
        position: item.position,
        skill_name: item.skill_name,
        skill_proficiency: item.skill_proficiency,
        type: skillResumeItemType.value,
      }
    case "CertificationResumeItem":
      return {
        identifier: nanoid(),
        id: item.id,
        position: item.position,
        certification_name: item.certification_name,
        issuing_org: item.issuing_org,
        issue_date: item.issue_date,
        expiration_date: item.expiration_date,
        credential_id: item.credential_id,
        credential_url: item.credential_url,
        type: certificationResumeItemType.value,
      }
    case "LanguageResumeItem":
      return {
        identifier: nanoid(),
        id: item.id,
        position: item.position,
        language_name: item.language_name,
        language_proficiency: item.language_proficiency,
        type: languageResumeItemType.value,
      }
    case "OrganizationResumeItem":
      return {
        identifier: nanoid(),
        id: item.id,
        organization: item.organization,
        title: item.title,
        location: item.location,
        start_date: item.start_date,
        end_date: item.end_date,
        ongoing: item.ongoing ?? false,
        description: item.description,
        type: organizationResumeItemType.value,
      }
    case "CourseResumeItem":
      return {
        identifier: nanoid(),
        id: item.id,
        course_name: item.course_name,
        course_website: item.course_website,
        institution: item.institution,
        start_date: item.start_date,
        end_date: item.end_date,
        ongoing: item.ongoing ?? false,
        description: item.description,
        type: courseResumeItemType.value,
      }
    case "ProjectResumeItem":
      return {
        identifier: nanoid(),
        id: item.id,
        project_name: item.project_name,
        project_website: item.project_website,
        role: item.role,
        start_date: item.start_date,
        end_date: item.end_date,
        ongoing: item.ongoing ?? false,
        description: item.description,
        type: projectResumeItemType.value,
      }
    case "CoverLetterResumeItem":
      return {
        identifier: nanoid(),
        id: item.id,
        company: item.company,
        company_street: item.company_street,
        company_city: item.company_city,
        first_paragraph: item.first_paragraph,
        second_paragraph: item.second_paragraph,
        third_paragraph: item.third_paragraph,
        closing: item.closing,
        is_freeform: item.is_freeform,
        freeform_text: item.freeform_text,
        type: coverLetterResumeItemType.value,
      }
  }
}

export const groupResumeItemsIntoSections = (
  items: ResumeItem[]
): Record<ResumeCategory, ResumeItem[]> => {
  const groupedItems: Record<ResumeCategory, ResumeItem[]> = {
    personal_info: [],
    summary: [],
    education: [],
    experience: [],
    skills: [],
    certifications: [],
    languages: [],
    organizations: [],
    projects: [],
    courses: [],
    cover_letter: [],
  }
  items.forEach((item) => {
    switch (item.type) {
      case educationResumeItemType.value:
        groupedItems.education.push(item)
        break
      case experienceResumeItemType.value:
        groupedItems.experience.push(item)
        break
      case skillResumeItemType.value:
        groupedItems.skills.push(item)
        break
      case certificationResumeItemType.value:
        groupedItems.certifications.push(item)
        break
      case languageResumeItemType.value:
        groupedItems.languages.push(item)
        break
      case summaryResumeItemType.value:
        groupedItems.summary.push(item)
        break
      case personalInfoResumeItemType.value:
        groupedItems.personal_info.push(item)
        break
      case organizationResumeItemType.value:
        groupedItems.organizations.push(item)
        break
      case courseResumeItemType.value:
        groupedItems.courses.push(item)
        break
      case coverLetterResumeItemType.value:
        groupedItems.cover_letter.push(item)
        break
      case projectResumeItemType.value:
        groupedItems.projects.push(item)
        break
    }
  })
  return groupedItems
}

export const resumeSectionItemsToItemParams = (
  sections: Record<ResumeCategory, ResumeItem[]>
): Record<ResumeCategory, ResumeItemParams[]> => {
  const params: Record<ResumeCategory, ResumeItemParams[]> = {
    personal_info: [],
    summary: [],
    education: [],
    experience: [],
    skills: [],
    certifications: [],
    languages: [],
    organizations: [],
    courses: [],
    projects: [],
    cover_letter: [],
  }
  Object.entries(sections).forEach(([category]) => {
    const categoryKey = category as ResumeCategory
    const items = sections[categoryKey]
    if (!Array.isArray(items)) {
      console.error(`Expected an array for category ${categoryKey}, but got:`, items)
      return
    }
    if (items) {
      params[category as ResumeCategory] = items.map(resumeItemToItemParams)
    }
  })
  return params
}
