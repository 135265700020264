import { z } from "zod"

export const WorkflowStatus = ["pending", "progress", "completed", "failed"] as const
export type WorkflowStatusType = (typeof WorkflowStatus)[number]

export const ZWorkflowInfo = z.object({
  id: z.string(),
  status: z.enum(WorkflowStatus).optional().nullable(),
})

export type WorkflowInfo = z.infer<typeof ZWorkflowInfo>
