<template>
  <div class="flex w-full flex-1 flex-col gap-12">
    <flash :type="flash.type" :message="flash.message" v-if="flash" />

    <h3 class="text-primary text-center font-medium">Sign In</h3>

    <FormKit type="form" :actions="false" #default="{ disabled }" @submit="handleSubmit">
      <div class="flex flex-col gap-8">
        <div class="flex flex-col gap-4">
          <FormKit
            type="text"
            v-model="email"
            autocomplete="off"
            placeholder="Your email address"
            validation-label="Email address"
            name="email"
            validation-visibility="dirty"
            validation="required" />

          <FormKit
            type="password"
            v-model="password"
            autocomplete="off"
            name="password"
            placeholder="Password"
            validation-label="Password"
            validation-visibility="dirty"
            validation="required" />
        </div>

        <FormKit type="checkbox" v-model="rememberMe" name="remember_me">
          <template #inner>
            <check-box-inner v-model="rememberMe">Remember Me</check-box-inner>
          </template>
        </FormKit>

        <div class="flex flex-col gap-4">
          <button type="submit" class="btn btn-primary px-10 py-3" :disabled="!!disabled">
            Login
          </button>

          <form-kit-messages />

          <div class="flex items-center justify-center gap-4">
            <template v-if="mode === 'signIn'">
              <button
                v-if="registerMode === 'sign_up'"
                type="button"
                class="primary-link flex-1 basis-1 text-right"
                @click="emit('switchRegistration', 'signUp')">
                Register here
              </button>
              <a href="/enroll" v-if="registerMode === 'enroll'" class="primary-link flex-1 basis-1 text-right">
                Enroll here
              </a>
            </template>

            <vertical-divider v-if="mode === 'signIn'" />

            <button
              type="button"
              class="primary-link flex-1 basis-1"
              :class="{ 'text-left': mode === 'signIn' }"
              @click="emit('switchRegistration', 'forgotPassword')">
              Forgot Password?
            </button>
          </div>

          <div v-if="showUnconfirmed">
            Didn't receive an email?
            <button
              type="button"
              class="primary-link self-start"
              @click="emit('switchRegistration', 'confirmationNew')">
              Resend confirmation
            </button>
          </div>
        </div>
      </div>
    </FormKit>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue"
import type { RegistrationType } from "/js/components/Registration/RegistrationTypes"
import { FormKitMessages } from "@formkit/vue"
import type { FormKitNode } from "@formkit/core"
import { useFlash } from "/js/composables/useFlash"
import Flash from "/js/components/Registration/Flash.vue"
import { ProfileApi } from "/js/services/ProfileApi"
import { getCustomErrorCode, getFormKitErrorMessage } from "/js/composables/getErrorMessage"
import CheckBoxInner from "/js/components/utilities/FormFields/CheckBoxInner.vue"
import VerticalDivider from "/js/components/utilities/UI/VerticalDivider.vue"
import { useAppSettings } from "/js/composables/useAppSettings"

const props = defineProps<{
  invitationEmail?: string
}>()

type Mode = "signIn" | "invitation"

const registerMode = useAppSettings().registerMode

const mode = computed((): Mode => {
  if (props.invitationEmail) {
    return "invitation"
  } else {
    return "signIn"
  }
})

const email = ref(props.invitationEmail || "")
const password = ref("")
const rememberMe = ref(false)

const showUnconfirmed = ref(false)

const flash = useFlash()

const emit = defineEmits<{
  switchRegistration: [value: RegistrationType]
}>()

const handleSubmit = async (formData: Record<string, unknown>, node: FormKitNode) => {
  node.clearErrors()
  try {
    await ProfileApi.signIn({
      email: email.value,
      password: password.value,
      remember_me: rememberMe.value,
    })
    window.location.reload()
  } catch (e) {
    showUnconfirmed.value = getCustomErrorCode(e) === "unconfirmed"
    node.setErrors(...getFormKitErrorMessage(e, formData))
  }
}

const handleEnroll = () => {
  window.location.href = "/enroll"
}
</script>

<script lang="ts">
export default {
  name: "SignInForm",
}
</script>
