import { z } from "zod"
import { ZResumeItem } from "/js/models/ResumeItem"
import { ZWorkflowInfo } from "/js/models/AIWorkflow"

export const ZResume = z.object({
  id: z.string().optional().nullable(),
  title: z.string().optional().nullable(),
  resume_items: z.array(ZResumeItem),
  created_at: z.date().optional().nullable(),
})

export const ZSimpleResume = ZResume.omit({ resume_items: true })

export type Resume = z.infer<typeof ZResume>
export type SimpleResume = z.infer<typeof ZSimpleResume>

export type ResumeParams = {
  title: string
}
export const ZResumeImportResult = z.object({
  ...ZWorkflowInfo.shape,
  resume: ZResume.optional().nullable(),
})

export type ResumeImportResult = z.infer<typeof ZResumeImportResult>

export type ResumeTabs = "edit" | "enhance" | "job_target"

export const ZJobRequirementsData = z.object({
  requirements: z.string().array().optional().nullable(),
  keywords: z.string().array().optional().nullable(),
})

export const ZJobRequirementsAnalysis = z.object({
  id: z.string().optional().nullable(),
  requirements: z.string().array().optional().nullable(),
  keywords: z.string().array().optional().nullable(),
  personal_info: ZJobRequirementsData.optional().nullable(),
  summary: ZJobRequirementsData.optional().nullable(),
  education: ZJobRequirementsData.optional().nullable(),
  experience: ZJobRequirementsData.optional().nullable(),
  skills: ZJobRequirementsData.optional().nullable(),
  certifications: ZJobRequirementsData.optional().nullable(),
  languages: ZJobRequirementsData.optional().nullable(),
})

export type JobRequirementsAnalysis = z.infer<typeof ZJobRequirementsAnalysis>
export type JobRequirementsData = z.infer<typeof ZJobRequirementsData>

export const ZJobAnalysisRatingData = z.object({
  rating: z.string().nullable().optional(),
  score: z.number().nullable().optional(),
})

export const ZResumeItemAnalysisSuggestion = z.object({
  item_id: z.string().optional().nullable(),
  item_analysis: z.string().optional().nullable(),
  suggested_action: z.string().optional().nullable(),
  suggested_keywords: z.string().array().optional().nullable(),
  suggested_accomplishments: z.string().array().optional().nullable(),
  suggested_rephrase: z.string().optional().nullable(),
})

export const ZResumeItemAnalysis = z.object({
  ...ZJobAnalysisRatingData.shape,
  item_suggestions: z.array(ZResumeItemAnalysisSuggestion).optional().nullable(),
})

export const ZJobTargetAccomplishments = z.object({
  suggested_accomplishments: z.string().array().optional().nullable(),
})

export const ZJobTargetSuggestedEntries = z.object({
  suggested_entries: z.string().array().optional().nullable(),
})

export const ZResumeJobTargetAnalysis = z.object({
  id: z.string().optional().nullable(),
  job_match_rating: z.number().nullable().optional(),
  resume_feedback: z.string().nullable().optional(),
  personal_info: z.object({
    job_title: z.string().nullable().optional(),
  }),
  summary: z.object({
    rephrasing: z.string().nullable().optional(),
  }),
  experience: ZJobTargetAccomplishments.optional().nullable(),
  education: ZJobTargetAccomplishments.optional().nullable(),
  skills: ZJobTargetSuggestedEntries.optional().nullable(),
  certifications: ZJobTargetAccomplishments.optional().nullable(),
  languages: ZJobTargetSuggestedEntries.optional().nullable(),
  organizations: ZJobTargetAccomplishments.optional().nullable(),
  courses: ZJobTargetAccomplishments.optional().nullable(),
  projects: ZJobTargetAccomplishments.optional().nullable(),
})

export const ZJobDescription = z.object({
  company: z.string(),
  job_title: z.string(),
  description: z.string(),
})

export const ZResumeJobTargetData = z.object({
  id: z.string(),
  job_description: ZJobDescription,
  requirements_data: ZJobRequirementsAnalysis,
  analysis_data: ZResumeJobTargetAnalysis,
})

export type ResumeJobTargetData = z.infer<typeof ZResumeJobTargetData>
export type JobDescription = z.infer<typeof ZJobDescription>

export type JobTargetAnalysisStep =
  | "waiting"
  | "jobRequirementsAnalysis"
  | "resumeJobAnalysis"
  | "complete"
  | "failed"

export const ZResumeJobAnalysis = z.object({
  id: z.string().optional().nullable(),
  ...ZJobAnalysisRatingData.shape,
  match_rating: z.number().nullable().optional(),
  match_analysis: z.string().nullable().optional(),
  personal_info: ZJobAnalysisRatingData.optional().nullable(),
  summary: ZResumeItemAnalysis.optional().nullable(),
  education: ZResumeItemAnalysis.optional().nullable(),
  experience: ZResumeItemAnalysis.optional().nullable(),
  skills: ZResumeItemAnalysis.optional().nullable(),
  certifications: ZResumeItemAnalysis.optional().nullable(),
  languages: ZResumeItemAnalysis.optional().nullable(),
})

export type ResumeJobAnalysis = z.infer<typeof ZResumeJobAnalysis>
export type ResumeItemAnalysis = z.infer<typeof ZResumeItemAnalysis>
export type ResumeItemAnalysisSuggestion = z.infer<typeof ZResumeItemAnalysisSuggestion>
export type JobAnalysisRatingData = z.infer<typeof ZJobAnalysisRatingData>
export type ResumeJobTargetAnalysis = z.infer<typeof ZResumeJobTargetAnalysis>

export type ResumeSuggestedEntry = {
  text: string
  added: boolean
}

export const RESUME_PX_PADDING = 48

export type ResumeCategory =
  | "personal_info"
  | "summary"
  | "education"
  | "experience"
  | "skills"
  | "certifications"
  | "languages"
  | "organizations"
  | "courses"
  | "projects"
  | "cover_letter"

export const resumeCategoryOrder: ResumeCategory[] = [
  "personal_info",
  "summary",
  "experience",
  "education",
  "certifications",
  "skills",
  "languages",
  "projects",
  "organizations",
  "courses",
]

export type CategoryJobRequirements = {
  title: string
  category: ResumeCategory
  requirements?: string[] | null
  keywords?: string[] | null
}

export const groupRequirementsByCategory = (
  jobRequirementsAnalysis: JobRequirementsAnalysis
): CategoryJobRequirements[] => {
  return [
    {
      title: "Personal info",
      category: "personal_info",
      requirements: jobRequirementsAnalysis.personal_info?.requirements,
      keywords: jobRequirementsAnalysis.personal_info?.keywords,
    },
    {
      title: "Summary",
      category: "summary",
      requirements: jobRequirementsAnalysis.summary?.requirements,
      keywords: jobRequirementsAnalysis.summary?.keywords,
    },
    {
      title: "Experience",
      category: "experience",
      requirements: jobRequirementsAnalysis.experience?.requirements,
      keywords: jobRequirementsAnalysis.experience?.keywords,
    },
    {
      title: "Education",
      category: "education",
      requirements: jobRequirementsAnalysis.education?.requirements,
      keywords: jobRequirementsAnalysis.education?.keywords,
    },
    {
      title: "Skills",
      category: "skills",
      requirements: jobRequirementsAnalysis.skills?.requirements,
      keywords: jobRequirementsAnalysis.skills?.keywords,
    },
    {
      title: "Certifications",
      category: "certifications",
      requirements: jobRequirementsAnalysis.certifications?.requirements,
      keywords: jobRequirementsAnalysis.certifications?.keywords,
    },
    {
      title: "Languages",
      category: "languages",
      requirements: jobRequirementsAnalysis.languages?.requirements,
      keywords: jobRequirementsAnalysis.languages?.keywords,
    },
  ]
}
