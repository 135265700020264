import { createRouter, createWebHistory, type RouteRecordRaw } from "vue-router"
import {
  learningHubsRoutes,
  namedRoutes as learningHubsNamedRoutes,
} from "/js/router/LearningHubRoutes"
import PageNotFound from "/js/components/PageNotFound.vue"
import ProductRegistrationApp from "/js/ProductRegistrationApp.vue"
import { deviseRoutes } from "/js/router/deviseRoutes"
import ContainerApp from "/js/ContainerApp.vue"
import CommunityRegistrationApp from "/js/CommunityRegistrationApp.vue"
import OnboardingApp from "/js/OnboardingApp.vue"
import { adminRouteNames, adminRoutes } from "/js/router/AdminRoutes"
import {
  channelRelativeRoutes,
  chatRouteNames,
  communityChannelRoutes,
  directMessageRelativeRoutes,
} from "/js/router/ChatRoutes"
import { profileRouteNames, profileRoutes } from "/js/router/ProfileRoutes"
import { type CommunityAbility, type ProductAbility, useGate } from "/js/composables/useGate"
import {
  communityEventsRoutes,
  namedRoutes as communityEventsNamedRoutes,
} from "/js/router/EventsRoutes"
import { communityMembersRoutes, communityMembersRouteNames } from "/js/router/MembersRoutes"
import { toastError } from "/js/composables/toast"
import StripeCheckoutsWaitApp from "../StripeCheckoutsWaitApp.vue"
import { communityContentRoutes } from "/js/router/CommunityContentRoutes"
import { usePageViewStore } from "/js/stores/PageViewStore"
import { communityEditMeta, communityReadMeta } from "/js/router/meta"
import { careerRouteNames, careerRoutes } from "/js/router/CareerRoutes"
import NProgress from "nprogress"
import { computed } from "vue"

const { addPageView } = usePageViewStore()

const sectionRoutes: RouteRecordRaw[] = [
  {
    name: "home",
    path: "/home",
    component: () => import("/js/components/Layout/SectionViews/CommunitySectionView.vue"),
    redirect: { name: "home.index" },
    children: [
      {
        name: "home.index",
        path: "",
        component: () => import("/js/components/Home/Home.vue"),
        meta: communityReadMeta,
      },
      {
        name: "home.edit",
        path: "edit",
        component: () => import("/js/components/Home/HomeEdit.vue"),
        meta: communityEditMeta,
      },
      ...communityChannelRoutes,
      ...communityEventsRoutes,
    ],
    meta: communityReadMeta,
  },
  ...communityMembersRoutes,
  ...communityContentRoutes,
  {
    name: "career",
    path: "/career",
    component: () => import("/js/components/Layout/SectionViews/CareerSectionView.vue"),
    redirect: { name: careerRouteNames.careerIndex },
    meta: communityReadMeta,
    children: careerRoutes,
  },
  {
    name: "learner_dashboard",
    path: "/dashboard",
    component: () => import("/js/components/Layout/SectionViews/FullSectionView.vue"),
    meta: communityReadMeta,
    redirect: { name: "learner_dashboard.index" },
    children: [
      {
        name: "learner_dashboard.index",
        path: "",
        component: () => import("/js/components/LearnerDashboard/LearnerDashboard.vue"),
        meta: communityReadMeta,
      },
    ],
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("/js/components/Layout/SectionViews/AdminSectionView.vue"),
    redirect: { name: adminRouteNames.dashboard },
    children: adminRoutes,
  },
  {
    path: "/hubs",
    component: () => import("/js/components/Layout/SectionViews/LearningSectionView.vue"),
    redirect: { name: learningHubsNamedRoutes.learning_hubs_index },
    children: learningHubsRoutes,
  },
  {
    path: "/personalized_learning",
    component: () => import("/js/components/Layout/SectionViews/PersonalSectionView.vue"),
    redirect: { name: "personal.index" },
    children: [
      {
        name: "personal.index",
        path: "",
        component: () => import("/js/components/Personal/PersonalCourseList.vue"),
      },
      {
        name: "personal.show",
        path: ":courseId",
        component: () => import("/js/components/Personal/PersonalCourseShow.vue"),
        props: true,
        children: [
          {
            name: "personal.show.object",
            path: ":learningObjectId",
            component: () => import("/js/components/Personal/PersonalCourseShow.vue"),
            props: true,
          }
        ]
      }
    ],
  },
  {
    path: "/community/events",
    component: () => import("/js/components/Layout/SectionViews/EventsSectionView.vue"),
    redirect: { name: communityEventsNamedRoutes.events_index },
    children: communityEventsRoutes,
  },
  {
    path: "/chat",
    name: "chat",
    component: () => import("/js/components/Layout/SectionViews/ChatSectionView.vue"),
    redirect: { name: chatRouteNames.direct.index },
    children: [...channelRelativeRoutes, ...directMessageRelativeRoutes],
  },
  {
    name: "assistant",
    path: "/zixie-chat",
    component: () => import("/js/components/Layout/SectionViews/ZixieChatSectionView.vue"),
    meta: communityReadMeta,
    redirect: { name: "assistant.index" },
    children: [
      {
        name: "assistant.index",
        path: "",
        component: () => import("/js/components/GptChat/GptChatGeneralView.vue"),
        meta: communityReadMeta,
      },
      {
        name: "assistant.show",
        path: ":id",
        component: () => import("/js/components/GptChat/GptChatGeneralView.vue"),
        meta: communityReadMeta,
        props: (route) => ({ chatId: route.params.id }),
      },
    ],
  },
  {
    path: "/notifications",
    name: "notifications",
    component: () => import("/js/components/Layout/SectionViews/FullSectionView.vue"),
    redirect: { name: "notifications.index" },
    children: [
      {
        path: "",
        name: "notifications.index",
        component: () => import("/js/components/Notifications/NotificationsList.vue"),
      },
    ],
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("/js/components/Layout/SectionViews/ProfileSectionView.vue"),
    redirect: { name: profileRouteNames.profileEdit },
    children: profileRoutes,
  },
]

const mainRoutes: RouteRecordRaw[] = [
  {
    name: "terms",
    path: "/terms",
    component: () => import("/js/components/Pages/Terms.vue"),
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/js/components/Pages/Privacy.vue"),
  },
  {
    name: "community_registration_app",
    path: "/community",
    component: CommunityRegistrationApp,
  },
  {
    name: "community_new_app",
    path: "/community/new",
    component: OnboardingApp,
  },
  {
    name: "enrollment_app",
    path: "/enroll",
    component: () => import("/js/components/UserEnrollmentOnboarding/UserEnrollmentOnboarding.vue"),
  },
  {
    name: "product_registration_app",
    path: "/hubs/:product_id/registration",
    component: ProductRegistrationApp,
    props: (route) => ({ productId: route.params.product_id }),
  },
  ...deviseRoutes,
  {
    name: "stripe_checkouts_wait",
    path: "/stripe/checkouts",
    component: StripeCheckoutsWaitApp,
  },
  {
    name: "container",
    path: "",
    component: ContainerApp,
    children: sectionRoutes,
    redirect: { name: "learner_dashboard" },
  },
  {
    path: "/:catchAll(.*)*",
    name: "PageNotFound",
    component: PageNotFound,
  },
  {
    path: "/error",
    name: "Error",
    component: () => import("/js/components/ErrorPage.vue"),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes: mainRoutes,
})

type RouteMetaAuthorize =
  | {
      classType: "Community"
      authorizeFor: CommunityAbility
      requiresPlatformPlan: boolean
    }
  | {
      classType: "Product"
      productIdKey: string
      authorizeFor: ProductAbility
      requiresPlatformPlan: boolean
    }

declare module "vue-router" {
  interface RouteMeta {
    layout?: "BlockBuilderLayout" | "MainLayout" | "FullScreenLayout"
    authorize?: RouteMetaAuthorize
  }
}

const {
  communityGateStatus,
  productGateStatus,
  fetchGateIfNeeded,
  hasPlatformAccess,
  hasCommunityAbility,
} = useGate()

let progressBarTimeout: number | undefined = undefined

const startProgressBar = () => {
  clearTimeout(progressBarTimeout)
  progressBarTimeout = setTimeout(() => {
    NProgress.start()
    NProgress.set(0.1)
  }, 200)
}

const stopProgressBar = () => {
  clearTimeout(progressBarTimeout)
  NProgress.done()
}

const isSignedIn = computed(() => !!window.APP_CONFIG.USER_ID)

router.beforeEach(async (to, from, next) => {
  if (to.name) {
    startProgressBar()
  }

  if (isSignedIn.value) {
    await fetchGateIfNeeded()
  }

  const authorize = to.meta.authorize
  if (!authorize) {
    return next()
  } else {
    if (window.APP_CONFIG.PLATFORM_SUBSCRIPTION) {
      if (authorize.requiresPlatformPlan) {
        if (!hasPlatformAccess.value) {
          if (hasCommunityAbility("community_billing")) {
            return next("/admin/billing")
          } else {
            return next("/profile")
          }
        }
      }
    }

    if (authorize.classType === "Community") {
      const status = communityGateStatus(authorize.authorizeFor)
      if (status === "authorized") {
        console.log("[Community] authorized", authorize.authorizeFor)
        return next()
      } else if (status === "requires_subscription") {
        // toastError("You need a subscription to access this page")
        console.log("[Community] requires subscription", status)
        return next("/community")
      } else if (status === "requires_permission") {
        toastError("You do not have permission to access this page")
        console.log("[Community] requires permission", status)
        return next("/community")
      } else {
        // failed / loading / todo: better handling
        console.log("[Community] failed / loading / todo: better handling - status:", status)
        return next()
      }
    } else if (authorize.classType === "Product") {
      const id = to.params[authorize.productIdKey]

      if (typeof id !== "string") {
        console.log("[Product] id not found in params")
        return next("/error")
      }

      const status = productGateStatus(id, authorize.authorizeFor)
      console.log({ status })

      if (status === "authorized") {
        console.log("[Product] authorized for", authorize.authorizeFor)
        return next()
      } else if (status === "requires_permission" || status === "requires_subscription") {
        // toastError(
        //   status === "requires_permission"
        //     ? "You do not have permission to access this page"
        //     : "You need a subscription to access this page"
        // )
        console.log("[Product] requires permission or subscription. Redirecting to /")
        return next({ name: "product_registration_app", params: { product_id: id } })
      } else {
        // failed / loading / todo: better handling
        console.log("[Product] failed / loading / todo: better handling")
        return next("/error")
      }
    }
  }
})

router.afterEach((to, from) => {
  addPageView(to.fullPath)
  stopProgressBar()
})

export default router
