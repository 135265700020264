import { z } from 'zod'
import { postZod } from "/js/composables/useAxios"

const PdfDocumentStatuses = ["pending", "generating", "generated", "failed"] as const

export const ZPdfDocument = z.object({
  id: z.string(),
  pdf_url: z.string().nullable(),
  status: z.enum(PdfDocumentStatuses),
})

export type PdfDocument = z.infer<typeof ZPdfDocument>

export const PdfDocumentApi = {
  createResumePdf: async (resumeId: string): Promise<PdfDocument> => {
    return await postZod(`/api/pdf/resumes/${resumeId}/generate`, {}, ZPdfDocument)
  }
}