import type { DeviseAppProps } from "/js/components/Registration/DeviseApp.vue"
import type { RouteRecordRaw } from "vue-router";

export const deviseRoutes: RouteRecordRaw[] = [
  {
    name: "sign_up",
    path: '/users/sign_up',
    component: () => import("/js/components/Registration/DeviseApp.vue"),
    props: { initialReg: 'signUp' } as DeviseAppProps
  },
  {
    name: "sign_in",
    path: '/users/sign_in',
    component: () => import("/js/components/Registration/DeviseApp.vue"),
    props: { initialReg: 'signIn' } as DeviseAppProps
  },
  {
    name: "new_user_password",
    path: '/users/password/new',
    component: () => import("/js/components/Registration/DeviseApp.vue"),
    props: { initialReg: 'forgotPassword' } as DeviseAppProps
  },
  {
    name: "edit_user_password",
    path: '/users/password/edit',
    component: () => import("/js/components/Registration/DeviseApp.vue"),
    props: { initialReg: 'forgotPassword' } as DeviseAppProps
  },
  {
    name: "new_user_confirmation",
    path: '/users/confirmation/new',
    component: () => import("/js/components/Registration/DeviseApp.vue"),
    props: { initialReg: 'confirmationNew' } as DeviseAppProps
  },
  {
    name: "edit_user_password",
    path: '/users/password/edit',
    component: () => import("/js/components/Registration/DeviseApp.vue"),
    props: { initialReg: 'editForgotPassword' } as DeviseAppProps
  },
  {
    name: "join_invitation",
    path: '/users/invitations/:token',
    component: () => import("/js/components/Registration/AcceptInvitation.vue")
  },
  {
    name: "enroll",
    path: "/enroll(/:token)?",
    component: () => import("/js/components/UserEnrollmentOnboarding/UserEnrollmentOnboarding.vue"),
  }
]