<template>
  <button class="rounded-xl hover:bg-gray-100 p-2 focus:bg-gray-200" type="button" :disabled>
    <slot></slot>
  </button>
</template>

<script setup lang="ts">
const props = withDefaults(defineProps<{
  disabled?: boolean
}>(), {
  disabled: false
})
</script>

<script lang="ts">
export default {
  name: "IconButton"
}
</script>