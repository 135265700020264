import type { ErrorMessages } from "@formkit/core"
import { AxiosError } from "axios"

export const getCustomErrorCode = (error: unknown): string | null => {
  if (!(error instanceof AxiosError)) {
    return null
  }
  return error.response?.data?.error_code
}

function getErrorMessage(error: unknown): string {
  if (typeof error === "string") {
    return error
  }

  if (error instanceof AxiosError) {
    if (error.request?.status === 500) {
      return "Internal server error"
    }

    if (error.response?.data) {
      const errors = error.response.data.errors || error.response.data.error
      if (errors) {
        if (typeof errors === "string") {
          return errors
        } else if (errors.base) {
          if (typeof errors.base === "string") {
            return errors.base
          } else if (Array.isArray(errors.base)) {
            return errors.base.join(", ")
          }
        } else {
          return Object.keys(errors)
            .map((k) => `${humanize(k)} ${errors[k]}`)
            .join(", ")
        }
      }
    }
  }

  if (error instanceof Error) {
    if (error.message) {
      return error.message
    }
  }

  return "Unknown error"
}

function humanize(str: string): string {
  // Remove leading underscores and '_id' suffix
  str = str.replace(/^_+|_id$/g, "")

  // Replace underscores with spaces, lowercase the result, and capitalize the first letter
  return str
    .replace(/_/g, " ")
    .toLowerCase()
    .replace(/^\w/, (c) => c.toUpperCase())
}

const getFormKitErrorMessage = (
  error: unknown,
  formKitData?: Record<string, unknown>
): [ErrorMessages, ErrorMessages?] => {
  if (typeof error === "string") {
    return [error]
  }

  if (typeof error !== "object" || error === null) {
    return ["Unknown error"]
  }

  if (error instanceof AxiosError) {
    if (!error.response || !error.response.data) {
      return [error.message || "Unknown error"]
    }

    const expectedKeys = Object.keys(formKitData || {})

    let baseErrorMessage = null
    let errorList = null

    const data = error.response.data

    if (typeof data === "string") {
      // usually this is a 500 error
      return [error.message || "Unknown error"]
    }

    const errors = data.error || data.errors

    if (typeof errors === "string") {
      baseErrorMessage = errors
    } else {
      // assuming errors is an Object now

      const baseKeys = Object.keys(errors).filter((k) => k === "base" || k.includes(".base"))

      if (baseKeys.length > 0) {
        baseKeys.forEach((k) => {
          if (typeof errors[k] === "string") {
            baseErrorMessage = errors[k]
          } else if (Array.isArray(errors[k])) {
            baseErrorMessage = errors[k].join(", ")
          }
        })
      }

      if (expectedKeys.length > 0) {
        const errorKeys = Object.keys(errors)
        const unexpectedKeys = errorKeys.filter(
          (k) => !expectedKeys.includes(k) && !baseKeys.includes(k)
        )
        if (unexpectedKeys.length > 0) {
          // create error messages and join them to baseErrorMessage
          const unexpectedErrorMessages = unexpectedKeys.map((k) => `${k} ${errors[k]}`)
          if (baseErrorMessage) {
            baseErrorMessage = `${baseErrorMessage}, ${unexpectedErrorMessages.join(", ")}`
          } else {
            baseErrorMessage = unexpectedErrorMessages.join(", ")
          }
        }
      }

      errorList = errors
    }

    if (baseErrorMessage) {
      // strip _ from baseErrorMessage
      baseErrorMessage = baseErrorMessage.replace(/_/g, " ")
    }

    return [baseErrorMessage || "Unknown error", errorList]
  }

  return ["Unknown error"]
}

export default getErrorMessage
export { getFormKitErrorMessage }
