import type { RouteRecordRaw } from "vue-router"
import { RouterView } from "vue-router"
import { communityReadMeta } from "/js/router/meta"
import { profileRouteNames } from "/js/router/ProfileRoutes"

export const careerRouteNames = {
  careerIndex: "career.index",
  careerPlaceholder: "career.placeholder",
  careerPageShow: "career.page.show",
  careerPageEdit: "career.page.edit",
  jobSearch: "career.jobSearch",
  jobSearchIndex: "career.jobSearch.index",
  jobBookmarks: "career.jobBookmarks.index",
  jobApplications: "career.jobApplications.index",
  jobMonitorIndex: "career.jobMonitor.index",
  jobSearchMembersIndex: "career.jobSearchMembers.index",
  resumeDevelopment: "career.resumeDevelopment",
  resumeDevelopmentIndex: "career.resumeDevelopment.index",
  resumeEdit: "career.resumeEdit",
  resumeAnalysis: "career.resumeDevelopment.analysis",
  interviewPrep: "career.interviewPrep",
}

export const careerRoutes: RouteRecordRaw[] = [
  {
    name: careerRouteNames.careerIndex,
    path: "",
    component: () => import("/js/components/Career/CareerHome.vue"),
    meta: communityReadMeta,
  },
  {
    name: careerRouteNames.jobSearch,
    path: "jobs",
    component: RouterView,
    redirect: { name: careerRouteNames.jobSearchIndex },
    children: [
      {
        name: careerRouteNames.jobSearchIndex,
        path: "",
        component: () => import("/js/components/JobSearch/JobSearch.vue"),
        meta: communityReadMeta,
      },
      {
        name: careerRouteNames.jobBookmarks,
        path: "bookmarks",
        component: () => import("/js/components/JobSearch/JobBookmarks.vue"),
        meta: communityReadMeta,
      },
      {
        name: careerRouteNames.jobApplications,
        path: "applications",
        component: () => import("/js/components/JobSearch/JobApplications.vue"),
        meta: communityReadMeta,
      },
      {
        name: careerRouteNames.jobMonitorIndex,
        path: "monitor/:monitorId",
        component: () => import("/js/components/JobSearch/JobMonitorPosts.vue"),
        props: true,
        meta: communityReadMeta,
      },
      {
        name: careerRouteNames.jobSearchMembersIndex,
        path: "members",
        component: () => import("/js/components/JobSearch/JobSearchMembers.vue"),
        props: true,
        meta: communityReadMeta,
      },
    ],
  },
  {
    name: careerRouteNames.resumeDevelopment,
    path: "resumeDevelopment",
    component: RouterView,
    redirect: { name: careerRouteNames.resumeDevelopmentIndex },
    children: [
      {
        name: careerRouteNames.resumeDevelopmentIndex,
        path: "resume_development",
        component: () => import("/js/components/Career/ResumeBuilder/ResumesHome.vue"),
      },
      {
        name: careerRouteNames.resumeEdit,
        path: "resume_development/:resume_id",
        component: () => import("/js/components/Career/ResumeBuilder/Resume/ResumeEdit.vue"),
        props: (route) => ({ resumeId: route.params.resume_id }),
      }
    ],
  },
  {
    name: careerRouteNames.interviewPrep,
    path: "interview_prep",
    component: () => import("/js/components/Career/InterviewPrep/InterviewPrep.vue"),
  },
  {
    path: "page/:pageId",
    name: careerRouteNames.careerPageShow,
    component: () => import("/js/components/Career/CareerPageShow.vue"),
    props: true,
  },
  {
    path: "page/:pageId/edit",
    name: careerRouteNames.careerPageEdit,
    component: () => import("/js/components/Career/CareerPageEdit.vue"),
    props: true,
  },
]
